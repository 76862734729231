<template>
  <div class="container">
    <div class="row">
      <maintop1 />
      <div
        :class="
          flag_nav ? 'main_fix_offset col-xl-10' : 'main_fix_width col-xl-12'
        "
      >
        <maintop2 class="member_balance justify-content-end" />
        <div
          class="C000052_root__a9yOi"
          style="max-height: 869px; overflow-y: auto; margin-top: 100px"
        >
          <div class="C000052_userInfo__vhVx0 d-flex align-items-center">
            <span>
              <div
                class="C000052_userAvatar__kEXTF position-relative"
                @click="flag_head = true"
              >
                <span
                  class="ant-avatar ant-avatar-circle ant-avatar-image ant-avatar-icon"
                  style="
                    width: 70px;
                    height: 70px;
                    line-height: 70px;
                    font-size: 35px;
                  "
                  ><img
                    :src="
                      require('@/assets/img/big_head/' +
                        big_head_img_set +
                        '.png')
                    " /></span
                ><span class="C000052_edit__YiCar"
                  ><span
                    role="img"
                    aria-label="edit"
                    class="anticon anticon-edit"
                    ><svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="edit"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        d="M880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32zm-622.3-84c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9z"
                      ></path></svg></span
                ></span>
              </div>
            </span>
            <div style="margin-right: 20px" class="w-100">
              <div
                class="C000052_userNameArea__E8xNW w-100 h-100 d-flex align-items-center"
              >
                <span class="C000052_userInfoName__9Na5t">{{ userid }}</span
                ><svg
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="width: 13px"
                  @click="Copy"
                  ref="copy"
                >
                  <path
                    d="M10.5333 4.66667H8.46662V2.20001C8.4648 1.67607 8.27043 1.17415 7.92588 0.803663C7.58133 0.433179 7.11455 0.22418 6.62728 0.222229H2.03928C1.55202 0.22418 1.08523 0.433179 0.740684 0.803663C0.396135 1.17415 0.201766 1.67607 0.199951 2.20001V7.13334C0.201766 7.65728 0.396135 8.1592 0.740684 8.52968C1.08523 8.90017 1.55202 9.10917 2.03928 9.11112H4.33328V11.3333C4.33328 11.9227 4.55102 12.4879 4.9386 12.9047C5.32617 13.3214 5.85184 13.5556 6.39995 13.5556H10.5333C11.0814 13.5556 11.6071 13.3214 11.9946 12.9047C12.3822 12.4879 12.6 11.9227 12.6 11.3333V6.8889C12.6 6.29953 12.3822 5.73429 11.9946 5.31755C11.6071 4.9008 11.0814 4.66667 10.5333 4.66667V4.66667ZM4.33328 6.8889V7.62964H2.03928C1.97867 7.62964 1.91865 7.6168 1.86265 7.59186C1.80666 7.56692 1.75577 7.53036 1.71292 7.48427C1.67006 7.43819 1.63606 7.38348 1.61286 7.32326C1.58967 7.26305 1.57773 7.19852 1.57773 7.13334V2.20001C1.57773 2.06838 1.62636 1.94215 1.71292 1.84907C1.79947 1.756 1.91687 1.70371 2.03928 1.70371H6.62728C6.7497 1.70371 6.86709 1.756 6.95365 1.84907C7.04021 1.94215 7.08884 2.06838 7.08884 2.20001V4.66667H6.39995C5.85184 4.66667 5.32617 4.9008 4.9386 5.31755C4.55102 5.73429 4.33328 6.29953 4.33328 6.8889V6.8889Z"
                    fill="#495A71"
                  ></path>
                </svg>
              </div>
              <div>
                <div class="C000052_vipArea__IficX">
                  <div
                    class="C000052_vipLevelArea__F-CJG align-items-center d-flex fw-bolder justify-content-center text-white"
                  >
                    {{ $t("home_text3") }} {{ vip }}
                  </div>
                  <span class="C000052_vipLevelName__H8TRa"
                    >{{ $t("home_text3") }} {{ vip }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div style="cursor: pointer">
            <div
              class="C000052_vipBlock__8AtFy"
              @click="this.$router.push('/VIP/introduce')"
            >
              <div>
                <img
                  class="C000052_vipLevelIcon__goPA8"
                  src="@/assets/img/invite/3CA2721CD70A429A28DEF09573B20C85.png"
                  alt=""
                /><span class="C000052_vipText__G4QVH"
                  >{{ $t("home_text3") }} {{ vip }}</span
                >
              </div>
              <div>
                <span class="C000052_vipText__G4QVH"
                  >{{ $t("home_text3") }} {{ next_vip }}</span
                ><span
                  role="img"
                  aria-label="question-circle"
                  class="anticon anticon-question-circle"
                  ><svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="question-circle"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                    ></path>
                    <path
                      d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"
                    ></path></svg>
                  </span>
              </div>
            </div>
            <div class="C000052_vip__i7B-6">
              <div class="C000052_vipProgress__YZG-Y">
                <div class="d-flex justify-content-between">
                  <div>
                    <span class="C000052_vipProgressTitle__sbjvK">{{
                      $t("deposit").toUpperCase()
                    }}</span>
                  </div>
                  <div>
                    <span class="C000052_vipProgressNum__WcG1p"
                      >{{ $t("cur") }} {{ deposit }}/{{ $t("cur") }}<span style="color: #f2c27f !important">{{
                        next_deposit
                      }}</span></span
                    >
                  </div>
                </div>
                <div class="C000052_vipProgressBar__oX2O5">
                  <div
                    class="ant-progress ant-progress-line ant-progress-status-active ant-progress-show-info ant-progress-small"
                  >
                    <div class="ant-progress-outer">
                      <div class="ant-progress-inner">
                        <div
                          class="ant-progress-bg"
                          :style="{
                            width: percent_deposit + '%',
                          }"
                          style="height: 12px; background: rgb(254, 215, 145)"
                        ></div>
                      </div>
                    </div>
                    <span class="ant-progress-text" title="0%"
                      >{{ percent_deposit }}%</span
                    >
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div>
                    <span class="C000052_vipProgressTitle__sbjvK">{{
                      $t("bet").toUpperCase()
                    }}</span>
                  </div>
                  <div>
                    <span class="C000052_vipProgressNum__WcG1p"
                      >{{ $t("cur") }} {{ bet }}/{{ $t("cur") }}<span style="color: #f2c27f !important">{{
                        next_bet
                      }}</span></span
                    >
                  </div>
                </div>
                <div class="C000052_vipProgressBar__oX2O5">
                  <div
                    class="ant-progress ant-progress-line ant-progress-status-active ant-progress-show-info ant-progress-small"
                  >
                    <div class="ant-progress-outer">
                      <div class="ant-progress-inner">
                        <div
                          class="ant-progress-bg"
                          :style="{
                            width: percent_bet + '%',
                          }"
                          style="height: 12px; background: rgb(26, 85, 239)"
                        ></div>
                      </div>
                    </div>
                    <span class="ant-progress-text" title="0%"
                      >{{ percent_bet }}%</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="C000052_list__VxC5i">
            <div
              class="C000052_listItem__vfMvU"
              @click="this.$router.push('/BO/invite')"
            >
              <div class="C000052_listItemIcon__lWUG7">
                <img src="@/assets/img/left/menu_ic_3_share.png" />
              </div>
              <span class="C000052_listItemTitle__Lr6Z0">{{
                $t("left_menu_text4")
              }}</span>
            </div>
            <div
              class="C000052_listItem__vfMvU"
              @click="this.$router.push('/ME/wallet')"
            >
              <div class="C000052_listItemIcon__lWUG7">
                <img src="@/assets/img/left/menu_ic_4_recharge.png" />
              </div>
              <span class="C000052_listItemTitle__Lr6Z0">{{
                $t("text_recharge")
              }}</span>
            </div>
            <div
              class="C000052_listItem__vfMvU"
              @click="this.$router.push('/ME/myacc')"
            >
              <div class="C000052_listItemIcon__lWUG7">
                <img src="@/assets/img/left/menu_ic_5_myaccount.png" />
              </div>
              <span class="C000052_listItemTitle__Lr6Z0">{{
                $t("myacc")
              }}</span>
            </div>
            <div
              class="C000052_listItem__vfMvU"
              @click="this.$router.push('/ME/history')"
            >
              <div class="C000052_listItemIcon__lWUG7">
                <img src="@/assets/img/left/menu_ic_6_record.png" />
              </div>
              <span class="C000052_listItemTitle__Lr6Z0">{{
                $t("left_menu_text5")
              }}</span>
            </div>
            <div
              class="C000052_listItem__vfMvU"
              @click="this.$router.push('/ME/activity')"
            >
              <div class="C000052_listItemIcon__lWUG7">
                <img src="@/assets/img/left/menu_ic_7_myactivity.png" />
              </div>
              <span class="C000052_listItemTitle__Lr6Z0">{{
                $t("myactivity")
              }}</span>
            </div>
            <div
              class="C000052_listItem__vfMvU"
              @click="this.$router.push('/ME/message')"
            >
              <div class="C000052_listItemIcon__lWUG7">
                <img src="@/assets/img/left/menu_ic_8_messages.png" />
              </div>
              <span class="C000052_listItemTitle__Lr6Z0">{{
                $t("left_menu_text6")
              }}</span>
            </div>
          </div>
          <div class="C000052_logOutArea__nR2pJ" @click="logout">
            <a
              ><span class="C000052_logOut__vgAU1">{{ $t("logout") }}</span></a
            >
          </div>
        </div>
      </div>
      <bighead
        @flag_val="flag_big_fun"
        v-if="flag_head && code != null"
      ></bighead>
      <Footer />
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import "@/assets/style/member.css";
import maintop1 from "./Main_top1.vue";
import Footer from "./Home/Footer.vue";
import maintop2 from "./Main_top2.vue";
import bighead from "./Home/big_head_img.vue";
import { mapGetters } from "vuex";

export default {
  name: "member",
  setup() {},
  data() {
    return {
      code: Cookies.get("code"),
      userid: localStorage.getItem("uid"),
      shareurl: "",
      flag_head: false,
      vip: 0,
      deposit: 0,
      bet: 0,
      next_vip: 0,
      next_deposit: 0,
      next_bet: 0,
      big_head_img_set: localStorage.getItem("big_logo"),
    };
  },
  computed: {
    ...mapGetters(["flag_nav"]),
    percent_deposit() {
      return this.deposit == 0
        ? 0
        : ((parseFloat(this.deposit.toString().replaceAll(",", "")) * 100) / parseFloat(this.next_deposit.toString().replaceAll(",", ""))).toFixed();
    },
    percent_bet() {
      return this.bet == 0 ? 0 : ((parseFloat(this.bet.toString().replaceAll(",", "")) * 100) / parseFloat(this.next_bet.toString().replaceAll(",", ""))).toFixed();
    },
  },
  components: {
    Footer,
    maintop1,
    maintop2,
    bighead,
  },
  watch: {},
  created() {
    if (this.code != null) {
      this.info();
      this.share();
    }
  },
  mounted() {},
  methods: {
    logout: function () {
      Cookies.remove("code");
      localStorage.removeItem("uid");
      localStorage.removeItem("utype");
      localStorage.removeItem("first");
      this.$router.go();
    },
    share: function () {
      let param = {
        action: "share_info",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          domain: window.location.host,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.shareurl = res.url;
        }
      });
    },
    info: function () {
      let param = {
        action: "utype",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.vip = res.vip;
          this.deposit = this.filter_number(res.deposit);
          this.bet = this.filter_number(res.bet);
          this.next_vip = res.next_vip;
          this.next_deposit = this.filter_number(res.next_deposit);
          this.next_bet = this.filter_number(res.next_bet);
        }
      });
    },
    Copy: function () {
      var content = this.shareurl;
      this.$copyText(content).then(
        () => {
          this.$MSG.API_popmsg(
            this.$t("text_copied") + "<br/>" + content,
            "",
            "success"
          );
        },
        (e) => {
          this.$MSG.API_popmsg(
            this.$t("text_notcopy") + "： " + e.text,
            "",
            "error"
          );
        }
      );
    },
    flag_big_fun: function (e, imgval) {
      this.flag_head = e;
      localStorage.setItem("big_logo", imgval);
      this.big_head_img_set = imgval;
    },
    filter_number: function (value) {
      if (!value) return "";
      const valueArray = value.toString().split(".");
      let regex = /(\d)(?=(?:\d{3})+$)/g;
      if (valueArray.length === 2) {
        const intPart = valueArray[0].toString().replace(regex, "$1,");
        let floatPart = "";
        floatPart = valueArray[1].toString();
        return intPart + "." + floatPart;
      } else {
        const intPart = value.toString().replace(regex, "$1,");
        return intPart;
      }
    },
  },
};
</script>
